t-buttongroup {
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  height: 28px;
}

/* SYNC-BORDERS: these values must sync up with $todd.settings.border_top */
t-buttongroup.border-top {
  border-top-width: 1px;
}

t-buttongroup.border-bottom {
  border-bottom-width: 1px;
}

t-buttongroup.border-left {
  border-left-width: 1px;
}

t-buttongroup.border-right {
  border-right-width: 1px;
}

t-buttongroup div.separator {
  height: 100%;
  position: relative;
  vertical-align: top;
  width: 1px;
}

t-buttongroup div.separator div {
  background-color: #000000;
  height: 100%;
}

t-buttongroup div.separator div:after {
  background: #000000;
  display: block;
  font-size: 0;
  line-height: 0;
  position: absolute;
}

t-buttongroup t-button {
  height: 100%;
  padding: 4px;
  border-left: 1px solid transparent;
}

t-buttongroup.horizontal div.separator {
  display: inline-block;
  height: 100%;
}

t-buttongroup.horizontal div.separator div:after {
  bottom: 2px;
  left: 0;
  right: 0;
  top: 0;
}

t-buttongroup.vertical div.separator {
  display: block;
  width: 100%;
}

t-buttongroup.vertical div.separator div {
  border-width: 0;
}

t-buttongroup.vertical div.separator div:after {
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}

t-buttongroup.vertical t-button {
  background: none;
}

t-buttongroup.vertical t-button:not(:last-child) {
  border-bottom-width: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

t-buttongroup.vertical t-button:not(:first-child) {
  border-top-width: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

t-buttongroup t-button.todd--disabled {
  opacity: 0.26;
}

t-buttongroup div.separator div {
  background-color: #b8b8b8;
  height: 100%;
}