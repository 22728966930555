.t-textedit__prefix, .t-textedit__suffix {
  margin-top: 5px;
  white-space: nowrap;
}

.t-textedit__prefix {
  display: inline-block;
  vertical-align: top;
  margin-right: 5px;
}

.t-textedit__suffix {
  display: inline-block;
  vertical-align: top;
  margin-left: 5px;
}

t-textedit t-button {
  height: 100%;
  padding: 4px;
  border-left: 1px solid transparent;
}

t-textedit t-button.todd--disabled {
  border: none;
}

t-textedit > input {
  min-width: 20px;
  min-height: 23px;
  height: 23px;
  position: relative;
  vertical-align: top;
  -webkit-appearance: none;
  border: 1px solid #b3b3b3;
  border-radius: 2px;
  padding: 0 5px;
  color: #000000;
  font-size: 12px;
  line-height: 16px;
  /*font-family: 'Roboto', sans-serif, Arial;*/
  background: #fcfbfb;
}
t-textedit > input:focus {
  border-color: #52aefe;
}
t-textedit > input[required] {
  background-color: #fcf8d0;
  border-color: #b3b3b3;
}
t-textedit > input[required]:focus {
  border-color: #52aefe;
}
t-textedit > input[readonly] {
  border-color: #b3b3b3;
  color: rgba(0, 0, 0, 0.5);
  background: url("~@mod-tollium/web/ui/skins/default/bgdisabled.svg") 0 0/48px 48px;
}
t-textedit > input[readonly]:focus {
  border-color: #b3b3b3;
}
t-textedit > input[readonly]:not(:focus) {
  text-overflow: ellipsis;
}

t-textedit:not(.textedit--hiderequiredifdisabled) input[required][readonly] {
  background: #fcf8d0 url("~@mod-tollium/web/ui/skins/default/bgreadonly-disabled.svg") 0 0/48px 48px;
  border-color: #b3b3b3;
  color: rgba(0, 0, 0, 0.5);
}
t-textedit:not(.textedit--hiderequiredifdisabled) input[required][readonly]:focus {
  border-color: #52aefe;
}

t-textedit > input ~ t-button.icon {
  position: absolute;
  right: 0;
  top: calc(50% - 8px);
  width: 16px;
  height: 16px;
  background: rgba(0, 0, 0, 0);
  transition: background 0.1s;
}

t-textedit > input ~ t-button.icon:not(.todd--disabled):hover {
  background: rgba(0, 0, 0, 0.1);
}

t-textedit > input ~ t-button.icon.button--active {
  background: rgba(0, 0, 0, 0.2);
}

t-textedit > input ~ t-button.icon .button__img {
  top: 0;
  left: 0;
  margin: 0;
}